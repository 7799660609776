.dashboard {
  &-nav {
    height: 70px;
    border-bottom: 3px solid #f8f8f8;
    display: flex;
    padding: 0 40px;
    @media (max-width: 769px) {
      padding: 0 20px;
    }

    &-menu {
      width: 35px;
      margin-right: 20px;
      @media (min-width: 769px) {
        display: none;
      }
    }

    &-user {
      margin-left: auto;
      display: flex;
      div {
        width: 50px;
        height: 50px;
        border: 1px solid #333333;
        color: white;
        background: #333333;
        border-radius: 50%;
        text-align: center;
        margin-top: 10px;
        @media (max-width: 769px) {
          width: 40px;
          height: 40px;
          margin-top: 15px;
        }

        h2 {
          margin-top: 7px;
          @media (max-width: 769px) {
            font-size: 23px;
          }
        }
      }
      p {
        font-size: 25px;
        margin: 20px 0 0 20px;
        @media (max-width: 769px) {
          display: none;
        }
      }
    }
  }

  &-content {
    padding: 20px 50px 40px !important;
    @media (max-width: 769px) {
      padding: 20px 20px 40px !important;
    }

    .text1 {
      color: #333333 !important;
      font-size: 18px;
      font-weight: 600;
    }
    .text2 {
      color: #667085 !important;
      font-size: 18px;
      font-weight: 600;
      padding: 11px;
      cursor: pointer;
    }
    .talent-tab-active {
      color: #773DD3 !important;
      border-bottom: 1px solid #773DD3;
      font-size: 18px;
      font-weight: 600;
      padding: 11px;
      cursor: pointer;
    }
    h3,
    h4 {
      color: #333333;
      margin: 40px 0 20px;
    }
    p,
    li {
      font-size: 17px;
      color: $color-formgrey;
    }
    ul {
      margin-left: 20px;
    }

    &-back {
      display: flex;
      cursor: pointer;
      margin-left: 20px;
      img {
        width: 15px;
      }
      p {
        margin: 5px 0 0 10px;
      }
    }

    &-top {
      display: flex;
      margin-bottom: 40px;

      &-btn1 {
        background: #773dd3;
        font-size: 15px;
        border: 1px solid #773dd3;
        color: white;
        border-radius: 5px;
        margin-left: 40px;
        height: 40px !important;
        padding: 0px 20px;
      }
    }

    &-search {
      margin-left: auto;
      position: relative;
      width: 300px;
      box-sizing: border-box;

      input {
        width: 100%;
        border: 1px solid #9da8b6;
        border-radius: 4px;
        font-size: 16px;
        height: 40px;
        padding: 10px 20px 10px 40px;
      }

      img {
        position: absolute;
        width: 15px;
        left: 15px;
        top: 12px;
      }
    }

    &-actions {
      display: flex;
      margin-left: auto;
      align-items: center;
      margin-bottom: 30px;
      position: relative;

      &-div {
        margin: 0 15px;
        cursor: pointer;
        .new-option {
          left: 0;
          top: 60px;
        }

        img {
          width: 15px;
          margin: -20px 7px 0 5px;
        }

        .det {
          width: 15px;
          margin: 0px 7px 0 5px;
        }

        .red-text {
          color: #eb4335 !important;
        }
      }
      
      &-drop{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
      &-btn {
        color: #d6d6d6;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
        width: 213px;
        padding: 13.5px 10px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      &-downloadBtn {
        border: 1px solid #d6d6d6;
        border-radius: 5px;
        background-color: #fff;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 213px;
        padding: 13.5px 10px;
        justify-content: center;
        align-items: center;
        margin-left: 24px;

        a {
          text-decoration: none;
          color: #d6d6d6;
        }
      }
      &-flex {
        display: flex;
        padding: 10px 16px;
        margin-top: 8px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        color: var(--787676, #787676);
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-bottom: 0.2px solid #787676;
      }

      &-dropdownContainer {
        // width: 369px;
        position: absolute;
        padding: 16px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        border-radius: 16px;
        background: #fff;
        z-index: 1;

        h5 {
          color: var(--text-color, #333);
          font-family: Nunito Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 175% */
        }

        .status {
          margin: 7px 10px;
          display: flex;
        }

        hr {
          border: 0.3px solid #e5e9f2;
          background-color: #e5e9f2;
        }
        .radio-item {
          display: inline-block;
          position: relative;
          margin-left: auto;
          cursor: pointer;
        }

        .radio-item input[type="radio"] {
          display: none;
        }

        .radio-item label:before {
          content: " ";
          display: inline-block;
          position: relative;
          top: 5px;
          right: 0;
          width: 16px;
          height: 16px;
          border-radius: 11px;
          border: 2px solid $color-inspiring-purple;
          background-color: transparent;
        }

        .radio-item input[type="radio"]:checked + label:after {
          border-radius: 11px;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 9px;
          right: 4px;
          content: " ";
          display: block;
          background: $color-inspiring-purple;
        }
      }
      &-drop {
        // display: none;
      }
      &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(232, 228, 235, 0.5); /* Semi-transparent background */
        //   backdrop-filter: blur(8px); /* Apply blur effect */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-byStatus {
        position: absolute;
        right: 0;
        top: 25px;
        width: 180px;
        height: 400px;
        overflow-y: auto;
        background-color: white;
        box-shadow: 0px 1px 1px 2px #e5e9f2;
        border-radius: 10px;

        .status {
          margin: 7px 10px;
          display: flex;
        }

        hr {
          border: 0.3px solid #e5e9f2;
          background-color: #e5e9f2;
        }
        .radio-item {
          display: inline-block;
          position: relative;
          margin-left: auto;
        }

        .radio-item input[type="radio"] {
          display: none;
        }

        .radio-item label:before {
          content: " ";
          display: inline-block;
          position: relative;
          top: 5px;
          right: 0;
          width: 16px;
          height: 16px;
          border-radius: 11px;
          border: 2px solid $color-inspiring-purple;
          background-color: transparent;
        }

        .radio-item input[type="radio"]:checked + label:after {
          border-radius: 11px;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 9px;
          right: 4px;
          content: " ";
          display: block;
          background: $color-inspiring-purple;
        }
      }
      &-guarantorBtnContainer {
        display: flex;
        gap: 10px;
        margin-left: auto;
        button {
          background-color: $color-inspiring-purple;
          padding: 13.5px 10px;
          width: 147px;
          border-radius: 5px;
          font-family: $font-secondary;
          font-size: 14px;
          font-weight: 700;
          line-height: normal;
          font-style: normal;
          color: #fff;
          border: none;
        }
        &-btn {
          background-color: #cc4945 !important;
        }
      }
    }

    &-table {
      table {
        border-collapse: collapse;
        width: 100%;
      }

      td,
      th {
        border-bottom: 1px solid #e5e9f2;
        text-align: left;
        padding: 15px 8px;
        font-size: 16px;
        color: #909198;
      }
      td {
        cursor: pointer;
      }

      th {
        font-weight: 500;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        text-transform: capitalize;
        text-align: center;
      }
      .duration {
        text-transform: lowercase;
      }
      &-action {
        background-color: white;
        border: 1px solid #773dd3;
        position: absolute;
        padding: 10px 10px 0;
        z-index: 1;
      }

      &-pagination {
        width: 350px;
        margin: 10px 0 0 auto;
        p {
          margin-right: 30px;
          text-transform: none !important;
        }

        .prev {
          margin: -15px 30px 0 0;
          transform: rotate(90deg);
          cursor: pointer;
        }

        .next {
          margin: -15px 0px 0 0;
          transform: rotate(270deg);
          cursor: pointer;
        }
      }

      .admitted {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: #188f32;
        background-color: #deffe5;
      }

      .rejected {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: #cc4945;
        background-color: #fff3f3;
      }

      .selected {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: $color-inspiring-purple;
        background-color: #dccdf4;
      }
      .appPaid {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: #20B15A;
        background-color:rgb(246, 248, 247);
      }
      .appNotPaid {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: #cc9600;
        background-color: #fffade;
      }


      .under-review {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: #cc9600;
        background-color: #fffade;
      }

      .not-submitted {
        padding: 2px 10px;
        border-radius: 10px;
        width: 100px;
        color: black;
        background-color: rgb(233, 233, 233);
      }

      .container {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .fee{
        border: 2px solid rgba(32, 177, 90, 0.1);
        border-radius: 100px;
        width: 93px;
        height: fit-content;
        text-align: center;
        // margin: 0 auto;
        padding: 2px 18px;
      }
      .paid{
        background-color: rgba(32, 177, 90, 0.1);
        color: rgba(32, 177, 90, 1);
      }
      .checkmark {
        position: absolute;
        top: -13px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 2px solid $color-formgrey;
        border-radius: 3px;
      }

      .container:hover input ~ .checkmark {
        background-color: #eee;
      }

      .container input:checked ~ .checkmark {
        background-color: $color-inspiring-purple;
        border: 2px solid $color-inspiring-purple;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      .container input:checked ~ .checkmark:after {
        display: block;
      }

      .container .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .socials{
        display: flex;
        gap: 10px;
        a{
          color: #909198;
          display: block;
        }
      }
    }
    #noshadow{
      box-shadow: none !important;
    }
    &-form {
      box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
      border-radius: 15px;
      padding: 40px 20px 100px 20px;
      margin: 20px 20px 50px;
      // hr{
      //   background: #dbdbdb;
      // }

      @media (max-width: 769px) {
        padding: 40px 20px 100px;
        margin: 0 0px 50px;
      }
      .guarantor1 {
        border: 0.1px solid #f5f5f5;
        border-radius: 24px;
        padding: 20px;
        margin-bottom: 56px;
        button {
          background-color: #cc4945;
          border-radius: 5px;
          padding: 13.5px 10px;
          width: 147px;
          border: none;
          font-family: $font-secondary;
          font-size: 14px;
          font-weight: 700;
          line-height: 19.1px;
          color: #fff;
          margin-top: 30px;
        }
      }
      .guarantor2 {
        border: 0.1px solid #f5f5f5;
        border-radius: 24px;
        padding: 20px;
        button {
          background-color: #cc4945;
          border-radius: 5px;
          padding: 13.5px 10px;
          width: 147px;
          border: none;
          font-family: $font-secondary;
          font-size: 14px;
          font-weight: 700;
          line-height: 19.1px;
          color: #fff;
          margin-top: 30px;
        }
      }

      &-textOption {
        display: flex;
        padding: 0 40px;
      }

      h6 {
        color: #aaaaaa;
        margin: 0 40px 0 0;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
      }
      .active {
        border-bottom: 2px solid #773dd3;
        color: #773dd3;
      }

      &-container {
        position: relative;
        box-sizing: border-box;
        h5 {
          margin-top: 10px;
          color: #787676;
          font-size: 17px;
        }
        .heading{
          color: #1D1E2C;
          font-weight: 700;
          font-size: 14px;
          line-height: 17.86px;
        }
        p {
          margin: 5px 0 10px;
          opacity: 0.6;
        }
        .text{
          color: #787676;
          font-weight: 500;
          font-size: 14px;
          line-height: 17.86px;
          margin: 12px 0 32px;
        }
        .fee{
          border: 2px solid rgba(32, 177, 90, 0.1);
          border-radius: 100px;
          width: 93px;
          height: fit-content;
          text-align: center;
          // margin: 0 auto;
          padding: 2px 18px;
        }
        .paid{
          background-color: rgba(32, 177, 90, 0.1);
          color: rgba(32, 177, 90, 1);
        }

        label {
          font-size: 16px;
          color: #333333;
          margin-top: 25px;
        }

        input,
        select,
        textarea {
          width: 100%;
          max-width: 700px;
          margin: 5px 0 0;
          padding: 7px 20px;
          font-size: 16px;
          color: $color-formgrey;
          border-radius: 10px;
          border: 1px solid #d9dce1;
        }
        option {
          text-transform: capitalize;
          padding: 4px 0;
        }
        textarea {
          height: 200px;
        }

        &-prev {
          color: $color-formgrey !important;
          background: white !important;
          border: 1px solid $color-formgrey !important;
          margin: 20px 15px 20px 0 !important;
          float: right;
        }

        &-image {
          width: 300px;
          margin-top: 10px;
          @media (max-width: 400px) {
            width: 100%;
          }
        }
      }

      &-basic {
        padding: 0 40px;
        h3 {
          color: #333333;
        }
        &-upload {
          display: flex;
          &-profile {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: #c4c4c4;
            overflow: hidden;
            img {
              width: 100%;
            }
          }

          button {
            width: 150px;
            text-align: center;
            font-size: 16px;
            border-radius: 4px;
            padding: 3px 0;
            border: 2px solid #773dd3;
          }
          &-btn1 {
            background-color: #773dd3;
            color: white;
            margin: 30px 20px 10px;
          }
          &-btn2 {
            background-color: white;
            color: #773dd3;
            margin: 0 20px;
          }
          p {
            margin-top: 30px;
            font-size: 15px;
          }
        }
      }

      &-edu {
        h3 {
          color: #333333;
          padding: 0 40px;
        }
        &-add {
          box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
          border-radius: 15px;
          max-width: 700px;
          border-left: 5px solid #773dd3;
          padding: 0 40px 40px;
          margin-bottom: 50px !important;
        }
        &-submit {
          max-width: 750px;
          button {
            width: 150px;
            text-align: center;
            font-size: 16px;
            border-radius: 4px;
            padding: 3px 0;
            border: 1px solid #773dd3;
            background-color: #773dd3;
            color: white;
            font-weight: 600;
            margin: auto;
            display: block;
          }
        }
      }
      &-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        &-delete {
          background-color: #eb4335;
          height: 33px;
          width: 33px;
          border-radius: 50%;
          margin: 3px 7px 0 0;
          cursor: pointer;
          img {
            width: 22px;
            margin: 5px 0 0 5px;
          }
        }
        &-add {
          height: 40px;
          width: 40px;
        }
      }
    }
    .appPaid {
      padding: 2px 10px;
      border-radius: 10px;
      width: 100px;
      color: #20B15A;
      background-color:rgb(246, 248, 247);
    }
    .appNotPaid {
      padding: 2px 10px;
      border-radius: 10px;
      width: 100px;
      color: #cc9600;
      background-color: #fffade;
    }
    &-detail {
      display: flex;
      padding: 10px 20px;
      &-p {
        padding: 7px 20px;
        margin-right: 10px;
        border-radius: 20px;
        cursor: pointer;
      }
      .active {
        color: white;
        background: $color-inspiring-purple;
      }

      &-actions {
        h3 {
          margin: 25px 15px 30px 20px;
          font-size: 21px;
        }
        p {
          margin-top: 28px;
        }
        .text2 {
          color: $color-inspiring-purple;
        }
      }

      &_form {
        box-sizing: border-box;

        label {
          font-size: 15px;
          font-weight: 600;
        }

        input {
          width: 100%;
          margin: 10px 0 30px;
          padding: 7px 15px;
          border: 1px solid $color-formgrey;
          border-radius: 2px;
        }

        &-textdiv {
          border: 1px solid $color-formgrey;
          border-radius: 2px;
          margin: 10px 0;
        }

        button {
          width: 100%;
          height: 40px;
          font-size: 16px;
          font-weight: 600;
          border: 1px solid $color-inspiring-purple;
          background-color: $color-inspiring-purple;
          color: white;
          border-radius: 4px;
          margin: 30px 0 10px;
        }
      }
    }
  }
}
