/*
0 - 560px:     Phone
560 - 769px    Tablet portrait
769 - 992px   Tablet landscape
992 - 1200px Desktop
>1200px  Big desktop

*/
/*
$breakpoint argument choices
- phone
- tab-port
- tab-land
- big-desktop

*/
*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}

input:focus, select:focus, textarea:focus {
  outline: 1px solid #ccc;
  border-radius: 10px;
}

.u-padding-none {
  padding: 0;
}

.u-margin-none {
  margin: 0;
}

.layout_auth-main {
  width: 65vw;
  font-family: "Nunito Sans", sans-serif;
  height: 100vh;
}
@media (max-width: 769px) {
  .layout_auth-main {
    width: 100vw;
  }
}
.layout_auth-image {
  width: 35vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
}
@media (max-width: 769px) {
  .layout_auth-image {
    display: none;
  }
}
.layout_dashboard-sidebar {
  position: fixed;
  width: 20vw;
  top: 0;
  left: 0;
  bottom: 0;
  background: #F8F8F8;
  height: 100vh;
}
@media (max-width: 769px) {
  .layout_dashboard-sidebar {
    z-index: 2;
    width: 200px;
    transition: 3s;
    left: -210px;
  }
}
.layout_dashboard-content {
  width: 80vw;
  float: right;
}
@media (max-width: 769px) {
  .layout_dashboard-content {
    width: 100vw;
  }
}

.sidebar {
  padding: 0 0 0 15%;
}
@media (max-width: 769px) {
  .sidebar {
    transition: 1s;
  }
}
.sidebar .logo {
  width: 150px;
  margin: 20px 0 40px;
}
@media (max-width: 992px) {
  .sidebar .logo {
    width: 100px;
  }
}
.sidebar-portal-heading {
  color: #333333;
  font-size: 19px !important;
  margin-bottom: 30px;
}
.sidebar-portal-links {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
.sidebar-portal-links p {
  margin-left: 10px;
}
.sidebar-portal-dashboardlink-main {
  display: flex;
  justify-content: center;
}
.sidebar-portal-dashboardlink-mainlink {
  margin: 0.5rem 0 0.8rem 1rem;
}
.sidebar-portal-dashboardlink a {
  color: #787676 !important;
  text-decoration: none !important;
  outline: none !important;
}
.sidebar-portal .active {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
.sidebar-portal .active p {
  margin-left: 10px;
  color: #333333;
}
.sidebar-portal .active path {
  fill: #333333;
}
.sidebar-portal .inactive {
  display: flex;
  margin: 10px 0;
  cursor: pointer;
}
.sidebar-portal .inactive p {
  margin-left: 10px;
  color: #787676;
}
.sidebar-portal .inactive path {
  fill: #787676;
}
.sidebar-portal .signout {
  margin-top: 45vh;
}

.auth_container-logo {
  width: 450px;
  margin: 50px auto 20px;
}
@media (max-width: 560px) {
  .auth_container-logo {
    width: 100%;
    margin: 30px 20px 0;
  }
}
.auth_container_form {
  box-shadow: 0px 1px 10px rgba(120, 118, 118, 0.15);
  border-radius: 10px;
  width: 450px;
  display: block;
  margin: 50px auto;
  padding: 30px 40px;
  box-sizing: border-box;
}
@media (max-width: 560px) {
  .auth_container_form {
    box-shadow: none;
    width: 100%;
    padding: 20px 20px 30px;
    margin-top: 20px;
  }
}
.auth_container_form form {
  margin-top: 30px;
}
.auth_container_form-email {
  width: 70px;
}
.auth_container_form-text {
  margin-bottom: 20px;
}
.auth_container_form-password {
  position: relative;
}
.auth_container_form-password div {
  width: 25px;
  position: absolute;
  top: 35px;
  right: 15px;
}
.auth_container_form-password div img {
  width: 20px;
}
.auth_container_form h3 {
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.auth_container_form p {
  color: #787676;
  margin-bottom: 20px;
}
.auth_container_form label {
  color: #333333;
  font-weight: 600;
  font-size: 15px;
}
.auth_container_form input {
  border: 1px solid #D9DCE1;
  border-radius: 7px;
  padding: 10px 20px;
  margin: 5px 0 20px;
  width: 100%;
  color: #787676;
}
.auth_container_form input:focus {
  outline: none;
}
.auth_container_form button {
  background: #773dd3;
  border: 1px solid #773dd3;
  width: 100%;
  padding: 10px 0;
  border-radius: 10px;
  margin: 10px 0 20px;
  text-align: center;
  color: white;
  font-weight: 600;
}
.auth_container_form span {
  color: #773dd3;
  font-weight: 700;
  cursor: pointer;
}
.auth_verify {
  text-align: center;
  margin: 30px 0;
}
.auth_verify img {
  display: block;
  margin: auto;
}

.dashboard-nav {
  height: 70px;
  border-bottom: 3px solid #f8f8f8;
  display: flex;
  padding: 0 40px;
}
@media (max-width: 769px) {
  .dashboard-nav {
    padding: 0 20px;
  }
}
.dashboard-nav-menu {
  width: 35px;
  margin-right: 20px;
}
@media (min-width: 769px) {
  .dashboard-nav-menu {
    display: none;
  }
}
.dashboard-nav-user {
  margin-left: auto;
  display: flex;
}
.dashboard-nav-user div {
  width: 50px;
  height: 50px;
  border: 1px solid #333333;
  color: white;
  background: #333333;
  border-radius: 50%;
  text-align: center;
  margin-top: 10px;
}
@media (max-width: 769px) {
  .dashboard-nav-user div {
    width: 40px;
    height: 40px;
    margin-top: 15px;
  }
}
.dashboard-nav-user div h2 {
  margin-top: 7px;
}
@media (max-width: 769px) {
  .dashboard-nav-user div h2 {
    font-size: 23px;
  }
}
.dashboard-nav-user p {
  font-size: 25px;
  margin: 20px 0 0 20px;
}
@media (max-width: 769px) {
  .dashboard-nav-user p {
    display: none;
  }
}
.dashboard-content {
  padding: 20px 50px 40px !important;
}
@media (max-width: 769px) {
  .dashboard-content {
    padding: 20px 20px 40px !important;
  }
}
.dashboard-content .text1 {
  color: #333333 !important;
  font-size: 18px;
  font-weight: 600;
}
.dashboard-content .text2 {
  color: #667085 !important;
  font-size: 18px;
  font-weight: 600;
  padding: 11px;
  cursor: pointer;
}
.dashboard-content .talent-tab-active {
  color: #773DD3 !important;
  border-bottom: 1px solid #773DD3;
  font-size: 18px;
  font-weight: 600;
  padding: 11px;
  cursor: pointer;
}
.dashboard-content h3,
.dashboard-content h4 {
  color: #333333;
  margin: 40px 0 20px;
}
.dashboard-content p,
.dashboard-content li {
  font-size: 17px;
  color: #787676;
}
.dashboard-content ul {
  margin-left: 20px;
}
.dashboard-content-back {
  display: flex;
  cursor: pointer;
  margin-left: 20px;
}
.dashboard-content-back img {
  width: 15px;
}
.dashboard-content-back p {
  margin: 5px 0 0 10px;
}
.dashboard-content-top {
  display: flex;
  margin-bottom: 40px;
}
.dashboard-content-top-btn1 {
  background: #773dd3;
  font-size: 15px;
  border: 1px solid #773dd3;
  color: white;
  border-radius: 5px;
  margin-left: 40px;
  height: 40px !important;
  padding: 0px 20px;
}
.dashboard-content-search {
  margin-left: auto;
  position: relative;
  width: 300px;
  box-sizing: border-box;
}
.dashboard-content-search input {
  width: 100%;
  border: 1px solid #9da8b6;
  border-radius: 4px;
  font-size: 16px;
  height: 40px;
  padding: 10px 20px 10px 40px;
}
.dashboard-content-search img {
  position: absolute;
  width: 15px;
  left: 15px;
  top: 12px;
}
.dashboard-content-actions {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}
.dashboard-content-actions-div {
  margin: 0 15px;
  cursor: pointer;
}
.dashboard-content-actions-div .new-option {
  left: 0;
  top: 60px;
}
.dashboard-content-actions-div img {
  width: 15px;
  margin: -20px 7px 0 5px;
}
.dashboard-content-actions-div .det {
  width: 15px;
  margin: 0px 7px 0 5px;
}
.dashboard-content-actions-div .red-text {
  color: #eb4335 !important;
}
.dashboard-content-actions-drop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.dashboard-content-actions-btn {
  color: #d6d6d6;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  width: 213px;
  padding: 13.5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.dashboard-content-actions-downloadBtn {
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 213px;
  padding: 13.5px 10px;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
}
.dashboard-content-actions-downloadBtn a {
  text-decoration: none;
  color: #d6d6d6;
}
.dashboard-content-actions-flex {
  display: flex;
  padding: 10px 16px;
  margin-top: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--787676, #787676);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 0.2px solid #787676;
}
.dashboard-content-actions-dropdownContainer {
  position: absolute;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  background: #fff;
  z-index: 1;
}
.dashboard-content-actions-dropdownContainer h5 {
  color: var(--text-color, #333);
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
}
.dashboard-content-actions-dropdownContainer .status {
  margin: 7px 10px;
  display: flex;
}
.dashboard-content-actions-dropdownContainer hr {
  border: 0.3px solid #e5e9f2;
  background-color: #e5e9f2;
}
.dashboard-content-actions-dropdownContainer .radio-item {
  display: inline-block;
  position: relative;
  margin-left: auto;
  cursor: pointer;
}
.dashboard-content-actions-dropdownContainer .radio-item input[type=radio] {
  display: none;
}
.dashboard-content-actions-dropdownContainer .radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 11px;
  border: 2px solid #773dd3;
  background-color: transparent;
}
.dashboard-content-actions-dropdownContainer .radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  right: 4px;
  content: " ";
  display: block;
  background: #773dd3;
}
.dashboard-content-actions-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(232, 228, 235, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-content-actions-byStatus {
  position: absolute;
  right: 0;
  top: 25px;
  width: 180px;
  height: 400px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 1px 1px 2px #e5e9f2;
  border-radius: 10px;
}
.dashboard-content-actions-byStatus .status {
  margin: 7px 10px;
  display: flex;
}
.dashboard-content-actions-byStatus hr {
  border: 0.3px solid #e5e9f2;
  background-color: #e5e9f2;
}
.dashboard-content-actions-byStatus .radio-item {
  display: inline-block;
  position: relative;
  margin-left: auto;
}
.dashboard-content-actions-byStatus .radio-item input[type=radio] {
  display: none;
}
.dashboard-content-actions-byStatus .radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 11px;
  border: 2px solid #773dd3;
  background-color: transparent;
}
.dashboard-content-actions-byStatus .radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  right: 4px;
  content: " ";
  display: block;
  background: #773dd3;
}
.dashboard-content-actions-guarantorBtnContainer {
  display: flex;
  gap: 10px;
  margin-left: auto;
}
.dashboard-content-actions-guarantorBtnContainer button {
  background-color: #773dd3;
  padding: 13.5px 10px;
  width: 147px;
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  font-style: normal;
  color: #fff;
  border: none;
}
.dashboard-content-actions-guarantorBtnContainer-btn {
  background-color: #cc4945 !important;
}
.dashboard-content-table table {
  border-collapse: collapse;
  width: 100%;
}
.dashboard-content-table td,
.dashboard-content-table th {
  border-bottom: 1px solid #e5e9f2;
  text-align: left;
  padding: 15px 8px;
  font-size: 16px;
  color: #909198;
}
.dashboard-content-table td {
  cursor: pointer;
}
.dashboard-content-table th {
  font-weight: 500;
  text-transform: uppercase;
}
.dashboard-content-table p {
  font-size: 14px;
  text-transform: capitalize;
  text-align: center;
}
.dashboard-content-table .duration {
  text-transform: lowercase;
}
.dashboard-content-table-action {
  background-color: white;
  border: 1px solid #773dd3;
  position: absolute;
  padding: 10px 10px 0;
  z-index: 1;
}
.dashboard-content-table-pagination {
  width: 350px;
  margin: 10px 0 0 auto;
}
.dashboard-content-table-pagination p {
  margin-right: 30px;
  text-transform: none !important;
}
.dashboard-content-table-pagination .prev {
  margin: -15px 30px 0 0;
  transform: rotate(90deg);
  cursor: pointer;
}
.dashboard-content-table-pagination .next {
  margin: -15px 0px 0 0;
  transform: rotate(270deg);
  cursor: pointer;
}
.dashboard-content-table .admitted {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #188f32;
  background-color: #deffe5;
}
.dashboard-content-table .rejected {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #cc4945;
  background-color: #fff3f3;
}
.dashboard-content-table .selected {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #773dd3;
  background-color: #dccdf4;
}
.dashboard-content-table .appPaid {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #20B15A;
  background-color: rgb(246, 248, 247);
}
.dashboard-content-table .appNotPaid {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #cc9600;
  background-color: #fffade;
}
.dashboard-content-table .under-review {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #cc9600;
  background-color: #fffade;
}
.dashboard-content-table .not-submitted {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: black;
  background-color: rgb(233, 233, 233);
}
.dashboard-content-table .container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.dashboard-content-table .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.dashboard-content-table .fee {
  border: 2px solid rgba(32, 177, 90, 0.1);
  border-radius: 100px;
  width: 93px;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  padding: 2px 18px;
}
.dashboard-content-table .paid {
  background-color: rgba(32, 177, 90, 0.1);
  color: rgb(32, 177, 90);
}
.dashboard-content-table .checkmark {
  position: absolute;
  top: -13px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #787676;
  border-radius: 3px;
}
.dashboard-content-table .container:hover input ~ .checkmark {
  background-color: #eee;
}
.dashboard-content-table .container input:checked ~ .checkmark {
  background-color: #773dd3;
  border: 2px solid #773dd3;
}
.dashboard-content-table .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.dashboard-content-table .container input:checked ~ .checkmark:after {
  display: block;
}
.dashboard-content-table .container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.dashboard-content-table .socials {
  display: flex;
  gap: 10px;
}
.dashboard-content-table .socials a {
  color: #909198;
  display: block;
}
.dashboard-content #noshadow {
  box-shadow: none !important;
}
.dashboard-content-form {
  box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
  border-radius: 15px;
  padding: 40px 20px 100px 20px;
  margin: 20px 20px 50px;
}
@media (max-width: 769px) {
  .dashboard-content-form {
    padding: 40px 20px 100px;
    margin: 0 0px 50px;
  }
}
.dashboard-content-form .guarantor1 {
  border: 0.1px solid #f5f5f5;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 56px;
}
.dashboard-content-form .guarantor1 button {
  background-color: #cc4945;
  border-radius: 5px;
  padding: 13.5px 10px;
  width: 147px;
  border: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.1px;
  color: #fff;
  margin-top: 30px;
}
.dashboard-content-form .guarantor2 {
  border: 0.1px solid #f5f5f5;
  border-radius: 24px;
  padding: 20px;
}
.dashboard-content-form .guarantor2 button {
  background-color: #cc4945;
  border-radius: 5px;
  padding: 13.5px 10px;
  width: 147px;
  border: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.1px;
  color: #fff;
  margin-top: 30px;
}
.dashboard-content-form-textOption {
  display: flex;
  padding: 0 40px;
}
.dashboard-content-form h6 {
  color: #aaaaaa;
  margin: 0 40px 0 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
.dashboard-content-form .active {
  border-bottom: 2px solid #773dd3;
  color: #773dd3;
}
.dashboard-content-form-container {
  position: relative;
  box-sizing: border-box;
}
.dashboard-content-form-container h5 {
  margin-top: 10px;
  color: #787676;
  font-size: 17px;
}
.dashboard-content-form-container .heading {
  color: #1D1E2C;
  font-weight: 700;
  font-size: 14px;
  line-height: 17.86px;
}
.dashboard-content-form-container p {
  margin: 5px 0 10px;
  opacity: 0.6;
}
.dashboard-content-form-container .text {
  color: #787676;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.86px;
  margin: 12px 0 32px;
}
.dashboard-content-form-container .fee {
  border: 2px solid rgba(32, 177, 90, 0.1);
  border-radius: 100px;
  width: 93px;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  padding: 2px 18px;
}
.dashboard-content-form-container .paid {
  background-color: rgba(32, 177, 90, 0.1);
  color: rgb(32, 177, 90);
}
.dashboard-content-form-container label {
  font-size: 16px;
  color: #333333;
  margin-top: 25px;
}
.dashboard-content-form-container input,
.dashboard-content-form-container select,
.dashboard-content-form-container textarea {
  width: 100%;
  max-width: 700px;
  margin: 5px 0 0;
  padding: 7px 20px;
  font-size: 16px;
  color: #787676;
  border-radius: 10px;
  border: 1px solid #d9dce1;
}
.dashboard-content-form-container option {
  text-transform: capitalize;
  padding: 4px 0;
}
.dashboard-content-form-container textarea {
  height: 200px;
}
.dashboard-content-form-container-prev {
  color: #787676 !important;
  background: white !important;
  border: 1px solid #787676 !important;
  margin: 20px 15px 20px 0 !important;
  float: right;
}
.dashboard-content-form-container-image {
  width: 300px;
  margin-top: 10px;
}
@media (max-width: 400px) {
  .dashboard-content-form-container-image {
    width: 100%;
  }
}
.dashboard-content-form-basic {
  padding: 0 40px;
}
.dashboard-content-form-basic h3 {
  color: #333333;
}
.dashboard-content-form-basic-upload {
  display: flex;
}
.dashboard-content-form-basic-upload-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #c4c4c4;
  overflow: hidden;
}
.dashboard-content-form-basic-upload-profile img {
  width: 100%;
}
.dashboard-content-form-basic-upload button {
  width: 150px;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  padding: 3px 0;
  border: 2px solid #773dd3;
}
.dashboard-content-form-basic-upload-btn1 {
  background-color: #773dd3;
  color: white;
  margin: 30px 20px 10px;
}
.dashboard-content-form-basic-upload-btn2 {
  background-color: white;
  color: #773dd3;
  margin: 0 20px;
}
.dashboard-content-form-basic-upload p {
  margin-top: 30px;
  font-size: 15px;
}
.dashboard-content-form-edu h3 {
  color: #333333;
  padding: 0 40px;
}
.dashboard-content-form-edu-add {
  box-shadow: 0px 5px 15px rgba(120, 118, 118, 0.12);
  border-radius: 15px;
  max-width: 700px;
  border-left: 5px solid #773dd3;
  padding: 0 40px 40px;
  margin-bottom: 50px !important;
}
.dashboard-content-form-edu-submit {
  max-width: 750px;
}
.dashboard-content-form-edu-submit button {
  width: 150px;
  text-align: center;
  font-size: 16px;
  border-radius: 4px;
  padding: 3px 0;
  border: 1px solid #773dd3;
  background-color: #773dd3;
  color: white;
  font-weight: 600;
  margin: auto;
  display: block;
}
.dashboard-content-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.dashboard-content-form-actions-delete {
  background-color: #eb4335;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  margin: 3px 7px 0 0;
  cursor: pointer;
}
.dashboard-content-form-actions-delete img {
  width: 22px;
  margin: 5px 0 0 5px;
}
.dashboard-content-form-actions-add {
  height: 40px;
  width: 40px;
}
.dashboard-content .appPaid {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #20B15A;
  background-color: rgb(246, 248, 247);
}
.dashboard-content .appNotPaid {
  padding: 2px 10px;
  border-radius: 10px;
  width: 100px;
  color: #cc9600;
  background-color: #fffade;
}
.dashboard-content-detail {
  display: flex;
  padding: 10px 20px;
}
.dashboard-content-detail-p {
  padding: 7px 20px;
  margin-right: 10px;
  border-radius: 20px;
  cursor: pointer;
}
.dashboard-content-detail .active {
  color: white;
  background: #773dd3;
}
.dashboard-content-detail-actions h3 {
  margin: 25px 15px 30px 20px;
  font-size: 21px;
}
.dashboard-content-detail-actions p {
  margin-top: 28px;
}
.dashboard-content-detail-actions .text2 {
  color: #773dd3;
}
.dashboard-content-detail_form {
  box-sizing: border-box;
}
.dashboard-content-detail_form label {
  font-size: 15px;
  font-weight: 600;
}
.dashboard-content-detail_form input {
  width: 100%;
  margin: 10px 0 30px;
  padding: 7px 15px;
  border: 1px solid #787676;
  border-radius: 2px;
}
.dashboard-content-detail_form-textdiv {
  border: 1px solid #787676;
  border-radius: 2px;
  margin: 10px 0;
}
.dashboard-content-detail_form button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #773dd3;
  background-color: #773dd3;
  color: white;
  border-radius: 4px;
  margin: 30px 0 10px;
}/*# sourceMappingURL=main.css.map */